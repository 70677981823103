import { Box, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { getAuthUser } from "src/pages/admin/services";
import { getResources } from "src/pages/services";
import {
  SET_RESOURCES,
  SET_USER,
  useAppDispatch,
  useAppState,
} from "src/state";
import { AdminHeader } from "../AdminHeader";
import { AdminNav } from "../AdminNav";

export const AdminLayout = ({ children }) => {
  const { user, resources } = useAppState((state) => ({
    user: state.user,
    resources: state.resources,
  }));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user) {
      (async function () {
        const [err, user] = await getAuthUser();

        if (!err) {
          dispatch({
            type: SET_USER,
            payload: user,
          });
        }
      })();
    }
  }, [user, resources, dispatch]);

  useEffect(() => {
    if (!resources) {
      (async function () {
        const [err, resources] = await getResources();

        if (!err) {
          dispatch({
            type: SET_RESOURCES,
            payload: resources,
          });
        }
      })();
    }
  }, [resources, dispatch]);

  return (
    <Flex gridGap="1px">
      <Box
        pos="sticky"
        w="300px"
        h="100vh"
        overflowX="auto"
        color="whiteAlpha.900"
        bg="primary.500"
      >
        <AdminNav />
      </Box>
      <Box flex="1" h="100vh" overflowY="auto">
        <AdminHeader user={user} />
        {children}
      </Box>
    </Flex>
  );
};
