import { defineStyle } from "@chakra-ui/styled-system";

const size = {
  xl: defineStyle({
    fontSize: "xl",
    px: 4,
    h: 16,
    borderRadius: "lg",
  }),
};

export const Input = {
  defaultProps: {
    size: "xl",
    w: "full",
  },
  sizes: {
    xl: {
      field: size.xl,
      addon: size.xl,
    },
  },
};
