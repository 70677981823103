import { colors } from "./colors";

export const foundations = {
  fonts: {
    heading: "RelishProBold, sans-serif",
    body: "RelishPro, sans-serif",
  },
  colors,
  sizes: {
    icon: {
      xs: "1rem",
      sm: "1.5rem",
      md: "2rem",
      lg: "2.5rem",
    },
  },
};
