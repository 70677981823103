import { useContext } from 'react';
import { AppContext } from '.';

export const useAppState = callback => {
    const { state } = useContext(AppContext);

    return callback(state);
};

export const useAppDispatch = () => {
    const { dispatch } = useContext(AppContext);

    return dispatch;
};
