import axios from "axios";
import { BASE_URL } from "../constants";

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/admin";
    }
    return Promise.reject(error);
  }
);

export default axios;
