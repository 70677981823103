import * as React from "react";
import { ChakraProvider } from "@chakra-ui/provider";
import Fonts from "./components/Fonts";
import { theme } from "./theme";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { AppStateProvider } from "./state";
import { ToastProvider } from "@chakra-ui/toast";

export const App = () => (
  <ChakraProvider theme={theme}>
    <ToastProvider />
    <Fonts />
    <React.Suspense fallback={<div>Loading...</div>}>
      <AppStateProvider>
        <RouterProvider router={router} />
      </AppStateProvider>
    </React.Suspense>
  </ChakraProvider>
);
