import { Global } from "@emotion/react";

const Fonts = () => {
  return (
    <Global
      styles={`
    @font-face {
  font-family: "RelishProBold";
  src: url("/fonts/relish-pro-bold.tff") format("tff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

    @font-face {
  font-family: "RelishPro";
  src: url("/fonts/relish-pro-bold.tff") format("tff");
  font-weight: bolder;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RelishPro";
  src: url("/fonts/relish-pro.tff") format("tff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RelishPro";
  src: url("/fonts/relish-pro-medium.tff") format("tff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
    `}
    />
  );
};

export default Fonts;
