import Http from "src/utils/https";

export const storeUser = ({ email, token }) => {
  // set username
  localStorage.setItem("seolahm_admin_email", email);

  //set token
  localStorage.setItem(`seolahm_admin_token`, token);

  //Add token to axios headers
  Http.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeUser = () => {
  localStorage.removeItem("seolahm_admin_email");

  localStorage.removeItem(`seolahm_admin_token`);
};

export const authCheck = () => {
  const email = localStorage.getItem("seolahm_admin_email");

  const token = localStorage.getItem("seolahm_admin_token");

  if (email && token) {
    //Add token to axios headers
    Http.defaults.headers.common.Authorization = `Bearer ${token}`;

    return true;
  }

  // remove both data in a case where only one is found
  removeUser();
  return false;
};

export const getAuthUser = async () => {
  try {
    const response = await Http.get("/admin/auth");
    return [null, response.data?.user];
  } catch (err) {
    return [err];
  }
};

export const updateCurrencyService = async ({ currencyId, rate }) => {
  try {
    const response = await Http.post(`/admin/update-currency/${currencyId}`, {
      rate,
    });
    return [null, response.data];
  } catch (err) {
    return [err];
  }
};
