import naira from 'src/assets/img/naira.png';
import dollar from 'src/assets/img/dollar.png';

// export const BASE_URL = "https://networth-api.onrender.com/api/v1/";
export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000/api/v1/'
    : 'https://seolahm-api-production.up.railway.app/api/v1/';

export const currencies = {
  NGN: {
    symbol: '₦',
    name: 'Naira',
    short: 'NGN',
  },
  USD: {
    symbol: '$',
    name: 'US Dollar',
    short: 'USD',
  },
};

export const luxuryItemsCollection = {
  value_of_cars: {
    title: 'Value of Cars',
  },
  value_of_jewelries: {
    title: 'Value of Jewelries',
  },
  value_of_artwork: {
    title: 'Value of Artwork',
  },
  value_of_luxury_bags: {
    title: 'Value of Luxury Bags',
  },
  value_of_paintings: {
    title: 'Value of Paintings',
  },
};

export const luxuryItems = [
  {
    label: 'Cars',
    value: 'value_of_cars',
  },
  {
    label: 'Jewelries',
    value: 'value_of_jewelries',
  },
  {
    label: 'Artwork',
    value: 'value_of_artwork',
  },
  {
    label: 'Luxury Bags',
    value: 'value_of_luxury_bags',
  },
  {
    label: 'Paintings',
    value: 'value_of_paintings',
  },
];

export const loanTypes = {
  car_loans: {
    title: 'Car loans',
    value: 'car_loans',
  },
  overdraft: {
    title: 'Overdraft',
    value: 'overdraft',
  },
  personal_loan: {
    title: 'Personal loan',
    value: 'personal_loan',
  },
  mortgage: {
    title: 'Mortgage',
    value: 'mortgage',
  },
  credit_cards: {
    title: 'Credit cards',
    value: 'credit_cards',
  },
  other_loans: {
    title: 'Other loans',
    value: 'other_loans',
  },
};

export const loanCollection = Object.values(loanTypes);

export const ageRangeCollection = {
  '18_to_25': { title: '18 - 25 years', value: '18_to_25' },
  '26_to_40': { title: '26 - 40 years', value: '26_to_40' },
  '41_to_65': { title: '41 - 65 years', value: '41_to_65' },
  above_65: { title: 'Above 65 years', value: 'above_65' },
};

export const ageRanges = Object.values(ageRangeCollection);

export const currencyList = [
  {
    title: 'NGN (₦)',
    image: naira,
    value: 'NGN',
  },
  {
    title: 'USD ($)',
    image: dollar,
    value: 'USD',
  },
];

export const staticAnswer = {
  ageRange: '6393403d9136661f5c7bfcb7',
  allowance: '1000',
  annual_rent: '30003',
  annual_school_fees: '2000',
  car_loans: '3000',
  cash_in_fx: '2000',
  cash_in_naira: '3000',
  child_support_payment: '2000',
  commission: '300',
  credit_cards: '1500',
  currency: '6393193972126efe201e135b',
  daily_spend: '2000',
  email: 'lateefabiodun@terawork.com',
  entertainment: '299',
  euro_bonds_value: '4440',
  fgn_bonds_value: '3000',
  firstName: 'Abdullateef',
  fixed_deposit_income: '400',
  health: '399',
  health_insurance: '300',
  housing: '488',
  income_from_business: '2000',
  lastName: 'Abiodun',
  miscellaneous: undefined,
  monthly_income: '3000',
  monthly_transport_cost: '3000',
  mortgage: undefined,
  other_expenses: '100',
  other_loans: '2000',
  other_valuables: undefined,
  overdraft: '1000',
  personal_loan: '3000',
  private_shares: '4000',
  public_shares: '20000',
  value_of_artwork: undefined,
  value_of_cars: '30003',
  value_of_houses: '3000',
  value_of_jewelries: '565',
  value_of_luxury_bags: '30003',
  value_of_paintings: undefined,
  value_of_precious_stones: '2000',
};
