import Http from "src/utils/https";

export const getResources = async () => {
  try {
    const response = await Http.get("/resources");
    return [null, response.data?.resources];
  } catch (err) {
    return [err];
  }
};
