import { SET_FORM_VALUE, SET_RESOURCES, SET_USER, SET_ORDERS } from "./actions";

export const initialState = {
  formValues: {},
  user: null,
  resources: null,
  orders: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
      return {
        ...state,
        formValues: { ...state.formValues, ...action.payload },
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_RESOURCES:
      return {
        ...state,
        resources: action.payload,
      };
    case SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    default:
      return state;
  }
};
