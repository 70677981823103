import { darken, transparentize } from "color2k";

export const colors = {
  primary: {
    100: transparentize("#004bee", 0.95),
    200: transparentize("#004bee", 0.8),
    500: "#004bee",
    600: darken("#004bee", 0.2),
  },
  secondary: {
    500: "#00143e",
    600: darken("#00143e", 0.2),
  },
  text: {
    body: "#303030",
    primary: "#004bee",
  },
  gray: {
    50: "#F9F9F9",
    100: "#F2F2F2",
    200: "#E0E0E0",
    400: "#BDBDBD",
    500: "#828282",
    600: "#4F4F4F",
    700: "#333333",
  },
  borderColor: "#BDBDBD",
  borderColorLight: "#F2F2F2",
};
