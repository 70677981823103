import { defineStyle } from "@chakra-ui/styled-system";

export const Heading = {
  defaultProps: {
    size: "md",
  },
  sizes: {
    md: defineStyle({
      fontSize: ["xl", null, "2xl"],
      lineHeight: [1.33, null, 1.2],
    }),
  },
};
