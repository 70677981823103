import { Avatar, Button, Flex, HStack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { removeUser } from "src/pages/admin/services";

export const AdminHeader = ({ user }) => {
  const navigate = useNavigate();
  const logout = () => {
    removeUser();

    navigate("/admin");
  };

  return (
    <Flex
      as="header"
      w="full"
      p="4"
      py="2"
      justify="space-between"
      borderBottomWidth="1px"
      mb="8"
    >
      <HStack spacing="4" align="center">
        <Avatar size="sm" />
        <Text fontWeight="bold" fontSize="lg">
          {user?.firstName} {user?.lastName}
        </Text>
      </HStack>
      <Button variant="ghost" colorScheme="red" shadow="none" onClick={logout}>
        Logout
      </Button>
    </Flex>
  );
};
