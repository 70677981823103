import React from "react";
import { initialState, reducer } from "./reducer";

type AppStateType = {
  state: any;
  dispatch: Function;
};

export const AppContext = React.createContext<AppStateType>({
  state: initialState,
  dispatch: () => {},
});

export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export * from "./useAppState";
export * from "./actions";
