export const Button = {
  baseStyle: {
    shadow: "lg",
    _hover: {
      shadow: "2xl",
    },
  },
  defaultProps: {
    size: "xl",
    colorScheme: "primary",
  },
  sizes: {
    xl: {
      h: "56px",
      fontSize: "lg",
      px: "32px",
      minW: "150px",
    },
    "2xl": {
      h: "64px",
      fontSize: "xl",
      px: "40px",
      minW: "150px",
    },
  },
  variants: {
    white: {
      backgroundColor: "whiteAlpha.900",
      color: "gray.500",
      _hover: {
        color: "gray.700",
      },
    },
  },
};
