import { Navigate } from "react-router-dom";
import { AdminLayout } from "src/components/Layout/AdminLayout";
import { authCheck } from "./services";

export const AdminRoute = ({ children }) => {
  const isAuthenticated = authCheck();

  return isAuthenticated ? (
    <AdminLayout>{children}</AdminLayout>
  ) : (
    <Navigate to="/admin" />
  );
};
