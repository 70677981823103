import { AtSignIcon, ChatIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  Icon,
  Image,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";
import logo from "src/assets/img/logo-white.png";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import CashIcon from "mdi-react/CashIcon";

const adminLinks = [
  { title: "Responses", href: "/admin/answers", icon: ChatIcon },
  { title: "Admin users", href: "/admin/users", icon: AtSignIcon },
  {
    title: "Payments",
    href: "/admin/orders",
    icon: CashIcon,
  },
  // {
  //   title: "Subscribers",
  //   href: "/admin/subscribers",
  //   icon: AccountGroupIcon,
  // },
  { title: "Currency", href: "/admin/currency", icon: CurrencyUsdIcon },
];

export const AdminNav = () => {
  return (
    <Box as="aside" p="6" py="10">
      <Link to="/admin/home">
        <Box maxW="180px">
          <Image src={logo} alt="logo" w="full" h="full" />
        </Box>
      </Link>

      <VStack
        as={UnorderedList}
        align="stretch"
        listStyleType="none"
        ml="0"
        mt="12"
        spacing="8"
      >
        {adminLinks.map(({ icon, ...item }) => (
          <ListItem key={item.href}>
            <Box display="block" pl="4" as={NavLink} to={item.href}>
              <HStack spacing="4">
                <Icon as={icon} boxSize="1.5rem" />
                <Text as="span">{item.title}</Text>
              </HStack>
            </Box>
          </ListItem>
        ))}
      </VStack>
    </Box>
  );
};
