export const styles = {
  global: {
    "*, *::before, &::after": {
      borderColor: "borderColor",
      wordWrap: "break-word",
    },
    body: {
      color: "text.body",
    },
  },
};
