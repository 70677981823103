import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import { AdminRoute } from "./pages/admin/route";
const Home = lazy(() => import("src/pages/user/Home"));
const CashFlow = lazy(() => import("src/pages/user/CashFlow"));
const Assets = lazy(() => import("src/pages/user/Assets"));
const FixedAssets = lazy(() => import("src/pages/user/FixedAssets"));
const Liabilities = lazy(() => import("src/pages/user/Liabilities"));
const Report = lazy(() => import("src/pages/user/Report"));
const Completed = lazy(() => import("src/pages/user/Completed"));
const AdminLogin = lazy(() => import("src/pages/admin/Login"));
const PasswordReset = lazy(() => import("src/pages/admin/Reset-password"));
const AdminHome = lazy(() => import("src/pages/admin/Home"));
const Answer = lazy(() => import("src/pages/admin/Answer"));
const Users = lazy(() => import("src/pages/admin/Users"));
const Currency = lazy(() => import("src/pages/admin/Currency"));
const Orders = lazy(() => import("src/pages/admin/Orders"));
const Subscribers = lazy(() => import("src/pages/admin/Subscribers"));

const userRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/cash-flow",
    element: <CashFlow />,
  },
  {
    path: "/assets",
    element: <Assets />,
  },
  {
    path: "/assets/fixed",
    element: <FixedAssets />,
  },
  {
    path: "/liabilities",
    element: <Liabilities />,
  },
  {
    path: "/completed",
    element: <Completed />,
  },
  {
    path: "/show-report/:id",
    element: <Report />,
  },
];

const adminRoutes = [
  {
    path: "/admin",
    element: <AdminLogin />,
  },
  {
    path: "/admin/reset-password",
    element: <PasswordReset />,
  },
  {
    path: "/admin/answers",
    element: (
      <AdminRoute>
        <AdminHome />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/answers/:id",
    element: (
      <AdminRoute>
        <Answer />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/users",
    element: (
      <AdminRoute>
        <Users />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/orders",
    element: (
      <AdminRoute>
        <Orders />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/currency",
    element: (
      <AdminRoute>
        <Currency />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/subscribers",
    element: (
      <AdminRoute>
        <Subscribers />
      </AdminRoute>
    ),
  },
];

export const router = createBrowserRouter([...userRoutes, ...adminRoutes]);
